export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Sprunkis",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://html-classic.itch.zone/html/11700918/index.html",
    domain: "sprunkis.online",
    gaId: "G-7KKRSWD8J4",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
